<template>
  <div>
    <faulted-phases-filter @active-change="filterActive = $event" :filter-model="filterModel" />
    <faulted-phases-table :filter-active="filterActive" :filter-model="filterModel" />
  </div>
</template>

<script>
import FaultedPhasesFilter from '@/views/Nsi/FaultedPhases/FaultedPhasesFilter';
import FaultedPhasesTable from '@/views/Nsi/FaultedPhases/FaultedPhasesTable';

export default {
  name: 'FaultedPhases',
  components: { FaultedPhasesTable, FaultedPhasesFilter },
  data() {
    return {
      filterModel: {
        nameFilter: '',
        parentNameFilter: ''
      },
      filterActive: true
    };
  }
};
</script>

<style scoped>

</style>
