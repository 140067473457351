<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="40%"
    :close-on-click-modal="false"
  >
     <div slot="title">
      Поврежденные фазы
      <el-button class="back_action_panel--first_element custom-button " @click="handleSave" type="text">
         <SaveIcon /> Сохранить
      </el-button>
   </div>
    <el-form :rules="rules" v-loading="loading" ref="faultedPhaseForm" :model="faultedPhase" label-width="12rem">
      <el-form-item prop="name" label="Наименование:">
        <el-input v-model="faultedPhase.name"></el-input>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import faultedPhasesApi from '@/api/nsi/faultedPhases';
import SaveIcon from '@/assets/icons/save.svg';

export default {
  name: 'FaultedPhaseModal',
  props: ['value', 'faultedPhase'],
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  components: { SaveIcon },
  data() {
    return {
      loading: false,
      rules: {
        name: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ]
      },
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    async addFaultedPhase(faultedPhase) {
      await faultedPhasesApi.addFaultedPhase(faultedPhase);
      this.$emit('on-create');
    },
    async updateFaultedPhase(faultedPhase) {
      await faultedPhasesApi.updateFaultedPhase(faultedPhase);
      this.$emit('on-update');
    },
    async validateForm() {
      const valid = await this.$refs.faultedPhaseForm.validate();
      return valid;
    },
    async handleSave() {
      const valid = await this.validateForm();
      if (!valid) return;
      const request = this.faultedPhase.id ? this.updateFaultedPhase : this.addFaultedPhase;
      this.loading = true;
      await request(this.faultedPhase);
      this.dialogVisible = false;
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
